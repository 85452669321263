import React, { useEffect, useState } from "react"
import { BsArrowUpShort as Icon } from "react-icons/bs"

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false)

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <div className="scroll-to-top w-full text-right font-serif">
      {isVisible && (
        <div onClick={scrollToTop}>
          <p className="text-gray-500 cursor-pointer">
            Back to top <Icon className="inline-block " />
          </p>
        </div>
      )}
    </div>
  )
}
