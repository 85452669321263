import { graphql } from "gatsby"
import React from "react"
import PageBuilder from "../components/PageBuilder"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Seo from "../components/utils/Seo"
import Layout from "../components/Layout"
import ImageBuilder from "../components/ImageBuilder"
import BackToTop from "../components/utils/BackToTop"

export const query = graphql`
  query {
    page: allSanityInteriordesign {
      nodes {
        title
        highlight

        meta {
          title
          description
        }
        image {
          asset {
            landscape: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        pageBuilder {
          ... on SanityDoubleCols {
            _type
            _key
            _rawCol2
            _rawCol1
          }

          ... on SanitySingleCol {
            _key
            _type
            _rawCol
          }
        }
        _rawImageLayoutbuilder(resolveReferences: { maxDepth: 10 })
        imageLayoutbuilder {
          ... on SanityGallery {
            images {
              asset {
                gatsbyImageData(width: 600, height: 700)
              }
            }
          }
          ... on SanitySingleImage {
            asset {
              gatsbyImageData(width: 1500, height: 900)
            }
          }
        }
      }
    }
  }
`

const InteriorDesignPage = props => {
  const page = props.data.page.nodes[0]

  return (
    <Layout>
      <Seo
        title={page.meta && page.meta.title ? page.meta.title : ""}
        description={
          page.meta && page.meta.description ? page.meta.description : ""
        }
      />

      <GatsbyImage
        className="w-full"
        image={page.image.asset.landscape}
        alt={page.title}
      />
      <div className="m-auto w-4/5">
        <h1 className="highlight">{page.highlight}</h1>
      </div>
      <PageBuilder
        className="pagebuilder"
        pageBuilder={page.pageBuilder}
        _rawPageBuilder={page._rawPageBuilder}
      />
      <ImageBuilder imageLayoutbuilder={page.imageLayoutbuilder} />
      <BackToTop />
    </Layout>
  )
}

export default InteriorDesignPage
